export default {
    quote: '44px', //'3vw', //44px
//'1vw', //13px


    h1: '3.5rem', //'64px',
    h2: '2.8rem', //'54px',
    h3: '2rem', //'28px',
    h4: '1.8rem', //'24px',
    body: '1rem', //'16px',
    subtitle: '1.5rem', //'22px',
    button: '1rem', //'17px',
    link: '0.9rem'//'13px',

}
